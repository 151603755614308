import { template as template_284893fa9b56457fb6770e3ca23df8ae } from "@ember/template-compiler";
const FKText = template_284893fa9b56457fb6770e3ca23df8ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
