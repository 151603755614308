import { template as template_043cd9cd8a844574aedfe83a17e4f4ff } from "@ember/template-compiler";
const FKLabel = template_043cd9cd8a844574aedfe83a17e4f4ff(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
